<template>
  <footer class="footer-wrapper biote">
    <LayoutCentered>
      <img :src="bioteLogo" alt="Biote Logo" class="logo" />
      <div class="parent">
        <div class="child">
          <p class="all-rights-reserve">
            &copy; biote Corp, LLC. All rights reserved
          </p>
          <p class="cursor-pointer hoverable" @click="navigateTo('TermsOfUse')">Terms of Use</p>
        </div>
        <div class="child">
          <div>
            <div class="flex">
              <a href="tel:8449800683">+1 (844)-980-0683</a>
            </div>
            <div class="flex">
              <a href="mailto:BioteRx@biote.com">BioteRx@biote.com</a>
            </div>
          </div>
        </div>
        <div class="child">
          <p class="cursor-pointer hoverable" @click="navigateTo('ReturnsAndCancellationPolicy')">Returns & Cancellation Policy</p>
        </div>
      </div>
    </LayoutCentered>
  </footer>
</template>

<script>
import LayoutCentered from "@/components/common/LayoutCentered.vue";

export default {
  name: "MainFooter",
  components: {
    LayoutCentered,
  },
  data() {
    return {
      bioteLogo: require("@/assets/biote/biote-white-logo.png"),
    };
  },
  methods: {
    navigateTo(page) {
      if (page === "TermsOfUse") {
        window.open("https://biote.com/terms-of-use", "_blank");
        return;
      }
      this.$router.push({
        name: page,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./MainFooter.scss";
</style>

